import React, { useState } from "react"
import ReactDOM from "react-dom"
import tw from "twin.macro"
import * as Survey from "survey-react"
import "survey-react/survey.css"

import TextPanel from "../textPanel"
import SignupForm from "../signupForm"

const surveyJSON = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "matrix",
          isAllRowRequired: true,
          name: "question1",
          title: "Your Work",
          columns: [
            {
              value: "1",
              text: "Strongly disagree",
            },
            {
              value: "2",
              text: "Disagree",
            },
            {
              value: "3",
              text: "Somewhat disagree",
            },
            {
              value: "4",
              text: "Neither agree nor disagree",
            },
            {
              value: "5",
              text: "Somewhat agree",
            },
            {
              value: "6",
              text: "Agree",
            },
            {
              value: "7",
              text: "Strongly agree",
            },
          ],
          rows: [
            "I always meet my deadlines",
            "I know exactly what my strengths are",
            "I always work to my full potential",
            "I am always consistent in how I treat people",
          ],
          rowsOrder: "random",
        },
        {
          type: "matrix",
          isAllRowRequired: true,
          name: "question2",
          title: "Your Motives",
          columns: [
            {
              value: "1",
              text: "Strongly disagree",
            },
            {
              value: "2",
              text: "Disagree",
            },
            {
              value: "3",
              text: "Somewhat disagree",
            },
            {
              value: "4",
              text: "Neither agree nor disagree",
            },
            {
              value: "5",
              text: "Somewhat agree",
            },
            {
              value: "6",
              text: "Agree",
            },
            {
              value: "7",
              text: "Strongly agree",
            },
          ],
          rows: [
            "I always look for the win/win",
            "I can always see clearly what motivates my colleagues",
            "I can navigate the organisation culture",
            "I always give credit to partners",
            "I always look for solutions that help people in my network",
            "I am always willing to help others",
            "I always give without expecting anything in return",
          ],
          rowsOrder: "random",
        },
        {
          type: "matrix",
          isAllRowRequired: true,
          name: "question3",
          title: "Your Words",
          columns: [
            {
              value: "1",
              text: "Strongly disagree",
            },
            {
              value: "2",
              text: "Disagree",
            },
            {
              value: "3",
              text: "Somewhat disagree",
            },
            {
              value: "4",
              text: "Neither agree nor disagree",
            },
            {
              value: "5",
              text: "Somewhat agree",
            },
            {
              value: "6",
              text: "Agree",
            },
            {
              value: "7",
              text: "Strongly agree",
            },
          ],
          rows: [
            "I always say what I mean",
            "I am always truthful",
            "I can easily create a business case for my solutions",
            "I always adapt my communication style to meet the needs of my audience",
          ],
          rowsOrder: "random",
        },
        {
          type: "matrix",
          name: "question4",
          title: "Your Emotions",
          isAllRowRequired: true,
          columns: [
            {
              value: "1",
              text: "Strongly disagree",
            },
            {
              value: "2",
              text: "Disagree",
            },
            {
              value: "3",
              text: "Somewhat disagree",
            },
            {
              value: "4",
              text: "Neither agree nor disagree",
            },
            {
              value: "5",
              text: "Somewhat agree",
            },
            {
              value: "6",
              text: "Agree",
            },
            {
              value: "7",
              text: "Strongly agree",
            },
          ],
          rows: [
            "I am always open to others point of view",
            "I never get involved in gossip",
            "I am always open to new ideas",
            "I can easily empathise with others",
            "I am always aware of my emotions",
            "Others always feel comfortable discussing personal things with me",
            "I often build relationships outside of my team/department",
          ],
          rowsOrder: "random",
        },
      ],
    },
  ],
  showProgressBar: "top",
  progressBarType: "questions",
}

const model = new Survey.Model(surveyJSON)

const Container = tw.div`
pb-12 md:pb-24 mx-auto w-1/3
`

const SurveyComponent = () => {
  console.log(Survey)
  const [isComplete, setIsComplete] = useState(false)
  console.log(isComplete)

  const onSurveyCompleteHandler = (survey, options) => {
    console.log("survey", survey)
    console.log("options", options)
    console.log("Survey results: " + JSON.stringify(survey.data))
  }
  return (
    <div>
      <TextPanel heading="Strengths assessment" />
      <Survey.Survey model={model} onComplete={onSurveyCompleteHandler} />
      {isComplete ? (
        <Container>
          <TextPanel heading="Thanks for completing the survey" />
          <SignupForm
            placeholder="Enter your email and..."
            buttonText="Get your results!"
          />
        </Container>
      ) : null}
    </div>
  )
}

export default SurveyComponent
