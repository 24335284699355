import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"

import SEO from "../layout/seo"
import SurveyComponent from "../components/survey"
import TextPanel from "../components/textPanel"
import Layout from "../layout"

const Container = tw.div`
container mx-auto mt-12 md:mt-24
`

const SurveyPage = () => {
  const data = useStaticQuery(graphql`
    query SurveyHeading {
      webinyHeadlessCms {
        getPageHeadings(where: { page: "Survey" }) {
          data {
            id
            heading
            subHeading
          }
        }
      }
    }
  `)

  const header = data.webinyHeadlessCms.getPageHeadings.data

  return (
    <>
      <SEO title={header.heading} description={header.subHeading} />
      <Layout>
        <Container>
          <SurveyComponent />
        </Container>
      </Layout>
    </>
  )
}

export default SurveyPage
